import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from '@chakra-ui/react';

import { VIEW_OUR_PARTNERS } from '@/constants/analytics';
import { StrapiSectionDetail } from '@/customtypes/global';
import { trackEvent } from '@/dapp-sdk-v2';
import BaseImage from '@/dapp-sdk-v2/components/Image';

import Section from '../Section';

interface PartnerProps {
  sectionDetails: StrapiSectionDetail;
  partnersData: {
    sectionId: string;
    label: string;
    ctaText: string;
    link: string;
    partners: {
      imgKey: string;
      link: string;
      is_coming_soon: boolean;
    }[];
  }[];
}

const Partners = ({ sectionDetails, partnersData }: PartnerProps) => {
  const tabsMap = partnersData.reduce(
    (acc: any, curr: any) => acc.set(curr.sectionId, curr),
    new Map(),
  );
  const tabsArray = Array.from(tabsMap.entries());
  const tabs = tabsArray.map((item: any) => item[0]);

  return (
    <Section
      title={sectionDetails.title}
      subTitle={sectionDetails.sub_title}
      id={sectionDetails.section_id}
    >
      <Box mt={{ base: '40px', lg: '64px' }}>
        <Center bg="#E6F8EF" w="full" borderRadius={{ base: 16, lg: 40 }}>
          <Tabs
            mt={{ base: 7, lg: '40px' }}
            variant="unstyled"
            maxW="fit-content"
            align="center"
          >
            <TabList
              bg="backgroundPrimary"
              borderRadius={16}
              border="1px solid"
              borderColor="#AEDFCA"
              p={1}
              h={'52px'}
              maxW="fit-content"
            >
              {tabs.map((tab) => {
                const tabLabel = tabsMap.get(tab).label;
                return (
                  <Tab
                    key={tab}
                    fontSize={{ base: 14, lg: 24 }}
                    fontWeight={{ base: 600, lg: 700 }}
                    borderRadius={8}
                    _selected={{ bg: 'primary', color: 'white' }}
                  >
                    {tabLabel}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels p="0">
              {tabs.map((tab) => {
                const { partners, ctaText, link } = tabsMap.get(tab);
                return (
                  <TabPanel
                    key={`tabPanel-${tab}`}
                    p={0}
                    px={{ base: 0, lg: 4 }}
                  >
                    <Flex
                      mt={{ base: 7, lg: 10 }}
                      justify={{ base: 'space-between', lg: 'center' }}
                      flexWrap="wrap"
                      gap="1rem"
                    >
                      {partners.map(
                        ({
                          imgKey,
                          label,
                          is_coming_soon,
                        }: {
                          imgKey: string;
                          label: string;
                          is_coming_soon: boolean;
                        }) => (
                          <HStack
                            key={label}
                            w={{
                              base: 328,
                              lg: partners?.length > 5 ? 260 : 360,
                            }}
                            h={100}
                            gap={4}
                            p={6}
                          >
                            <BaseImage
                              src={imgKey}
                              alt={label}
                              width={48}
                              height={48}
                            />

                            <Flex flexDir="column" textAlign="left">
                              <Text fontSize="20px" fontWeight={600}>
                                {label}
                              </Text>
                              {is_coming_soon && (
                                <Tag
                                  key="coming soon"
                                  bg="#2196F3"
                                  color="white"
                                  fontWeight="700"
                                  fontSize="10px"
                                  p="2px 8px"
                                  letterSpacing="0.4px"
                                  w="max-content"
                                  borderRadius="6px"
                                >
                                  COMING SOON
                                </Tag>
                              )}
                            </Flex>
                          </HStack>
                        ),
                      )}
                      {ctaText && (
                        <Center
                          w={{ base: 328, lg: 360 }}
                          h={100}
                          p={6}
                          justifyContent="flex-start"
                        >
                          <Button
                            as="a"
                            href={link}
                            w={201}
                            h={58}
                            color="textPrimary"
                            bg="white"
                            border="1px"
                            borderColor="primary"
                            _hover={{
                              color: 'white',
                              bg: 'primary',
                            }}
                            onClick={() => {
                              trackEvent(VIEW_OUR_PARTNERS);
                            }}
                          >
                            {ctaText}
                          </Button>
                        </Center>
                      )}
                    </Flex>
                  </TabPanel>
                );
              })}
              {/* <TabPanel p="0">
                <Flex
                  mt={{ base: 7, lg: 10 }}
                  justify="space-between"
                  flexWrap="wrap"
                >
                  {ecosystem.map(({ label, image }) => (
                    <HStack
                      key={label}
                      w={{ base: 328, lg: 360 }}
                      maxW="100%"
                      h={{ base: "96px", lg: "100px" }}
                      gap={4}
                      p={6}
                    >
                      <BaseImage src={image} alt={label} />
                      <Box>
                        <Text fontSize="20px" fontWeight={600}>
                          {label}
                        </Text>
                      </Box>
                    </HStack>
                  ))}
                </Flex>
              </TabPanel>
              <TabPanel p="0">
                <Flex
                  mt={{ base: 7, lg: 10 }}
                  justify="space-between"
                  flexWrap="wrap"
                >
                  {institutions.map(({ label, image }) => (
                    <HStack
                      key={label}
                      w={{ base: 328, lg: 360 }}
                      maxW="100%"
                      h={{ base: "96px", lg: "100px" }}
                      gap={4}
                      p={6}
                    >
                      <BaseImage src={image} alt={label} />
                      <Box>
                        <Text fontSize="20px" fontWeight={600}>
                          {label}
                        </Text>
                      </Box>
                    </HStack>
                  ))}
                  <Center
                    w={{ base: 328, lg: 360 }}
                    maxW="100%"
                    h={100}
                    p={6}
                    justifyContent="flex-start"
                  >
                    <Button
                      as="a"
                      href="/partners"
                      variant="outline"
                      w={201}
                      h={58}
                      border="1px solid #AEDFCA"
                      bg="#FFFFFF"
                      fontWeight="600"
                      fontSize="16px"
                      _hover={{
                        bg: "primary",
                        color: "white",
                      }}
                    >
                      View all partners
                    </Button>
                  </Center>
                </Flex>
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Center>
      </Box>
    </Section>
  );
};

export default Partners;
